
<template>
  <e-button-loading
    class="add-to-cart"
    :loading="loading"
    :class="{
      'is-rounded': rounded,
      'add-spacing': !loading,
      'is-small': small
    }"
    :disabled="disabled"
    :size="small ? 'small' : ''"
    @click.stop.prevent="addToCart"
  >
    <q-icon
      name="cart"
      :class="{ 'text-base': small }"
    ></q-icon>
    <slot />
  </e-button-loading>
</template>

<script>
  import { useCart, useProduct } from '@propeller-commerce/propeller'
  import { ref, useRoute } from '@nuxtjs/composition-api'
  import { useModal } from '@/composables/useModal'
  import { setProductsListTracking } from '~/utils/utils'
  import { gtmSendEvent } from '~/utils/gtm-send-event'
  import { useAttribute, useListAttribute } from '~/composables/useAttribute'
  import { productAttributes } from '~/constants/products.module'

  export default {
    props: {
      small: {
        type: Boolean,
        default: false
      },
      rounded: {
        type: Boolean,
        default: false
      },
      product: {
        type: Object,
        default: () => ({ id: 1 })
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    setup (props, { root }) {
      const { openModal } = useModal(root)
      const { addItem } = useCart()
      const route = useRoute()
      const { products, search } = useProduct(`products-${props.product.productId}`)
      const getProduct = async (productId) => {
        await search({ id: productId }).catch(err => console.error(err))
      }
      const loading = ref(false)

      const trackAddToCart = (product, productType, level) => {
        const products = setProductsListTracking([{
          ...product,
          name: product.name[0].value,
          productType,
          level,
          quantity: 1
        }])

        delete products[0].index

        gtmSendEvent('add_to_cart', {
          ecommerce: {
            currency: 'EUR',
            items: products
          },
        }, null, true)
      }

      const addToCart = async () => {
        loading.value = true

        await addItem({
          product: props.product,
          quantity: 1
        }).then(() => {
          trackAddToCart(props.product, useAttribute(props.product, productAttributes.brand), useListAttribute(props.product, productAttributes.educationType).value.join(', '))
        })

        loading.value = false
        if (route.value.path !== '/producten') {
          openModal('AddToCart', {
            product: props.product
          })
        }

        if (route.value.path === '/producten') {
          // get full product to get related products (is impossible w/ shortened product from loop)
          getProduct(props.product.productId).then(() => {
            openModal('AddToCart', {
              product: products.value
            })
          })
        }
      }

      return {
        addToCart,
        loading
      }
    }
  }
</script>

<style lang="scss">
.is-small {
  @apply text-sm #{!important};
}

.add-to-cart {
  &.is-rounded {
    @apply rounded-full #{!important};
  }
  &.add-spacing .content {
    @apply space-x-2;
  }
}
</style>
