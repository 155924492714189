<template>
  <transition
    name="fade"
    mode="out-in"
  >
    <div @click="() => trackProductClick(product, productType, levels, price)">
      <component
        :is="interactive ? 'nuxt-link' : 'div'"
        :to="interactive ? `/producten/${id}/${slug}` : ''"
      >
        <card-base
          class="card-product w-full"
          :interactive="!minimal"
          :class="{
            'is-minimal': minimal,
            'is-disabled': disabled,
            'is-active': active
          }"
        >
          <header>
            <h3
              class="product-title"
              :title="name"
            >
              {{ name }}
            </h3>
            <p class="product-subtitle text-base">
              {{ productType }}
            </p>
            <p class="text-gray-600 product-category text-base">
              {{ levels }}
            </p>
          </header>
          <figure class="product-image mb-6 mt-4 pointer-events-none flex-shrink-0">
            <img
              :src="coverImage"
              :alt="name"
              class="mx-auto"
            >
          </figure>
          <footer v-if="!minimal">
            <div class="flex justify-between items-end">
              <div class="flex flex-col items-start space-y-2">
                <ProductPrice
                  :price="price.regular"
                  :discount="price.special"
                  direction="col"
                />
                <Badge v-if="productType !== 'Voordeelpakket'">
                  Stapelkorting
                </Badge>
              </div>
              <div>
                <ProductAddToCart
                  class="w-12 h-12"
                  rounded
                  :disabled="!isOrderable"
                  :product="product"
                />
              </div>
            </div>
          </footer>
        </card-base>
      </component>
    </div>
  </transition>
</template>

<script>
  import { productGetters } from '@propeller-commerce/propeller'
  import { computed } from '@nuxtjs/composition-api'

  import { useAttribute, useListAttribute } from '@/composables/useAttribute'
  import Badge from '@/components/Ecommerce/Badge'
  import CardBase from '@/components/CardBase'
  import ProductPrice from '@/components/Ecommerce/Products/ProductPrice'
  import ProductAddToCart from '@/components/Ecommerce/Products/ProductAddToCart'
  import { productAttributes } from '@/constants/products.module'
  import { setProductsListTracking } from '~/utils/utils'
  import { gtmSendEvent } from '~/utils/gtm-send-event'

  export default {
    components: {
      Badge,
      CardBase,
      ProductPrice,
      ProductAddToCart
    },
    props: {
      product: {
        type: Object,
        default: null
      },
      minimal: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      active: {
        type: Boolean,
        default: false
      },
      interactive: {
        type: Boolean,
        default: true
      }
    },
    setup (props) {
      const trackProductClick = (product, productType, level) => {
        const productList = setProductsListTracking([{
          ...product,
          name: product.name[0].value,
          productType,
          level,
        }])
        gtmSendEvent('select_item', {
          ecommerce: {
            currency: 'EUR',
            items: productList
          },
          item_list_name: 'Productpagina',
          item_list_id: 'productpagina'
        }, null, true)
      }

      return {
        name: computed(() => props.product.shortName),
        id: computed(() => productGetters.getId(props.product) || props.product.id),
        coverImage: computed(() => productGetters.getCoverImage(props.product)),
        price: computed(() => productGetters.getPrice(props.product)),
        slug: computed(() => productGetters.getSlug(props.product) || props.product.slug),
        productType: useAttribute(props.product, productAttributes.brand),
        levels: computed(() => useListAttribute(props.product, productAttributes.educationType).value.join(', ')),
        isOrderable: computed(() => props.product.isOrderable !== 'N'),
        trackProductClick
      }
    }
  }
</script>

<style lang="scss" scoped>
.card-product {
  @apply h-full p-6 text-center;

  .product-title {
    @apply font-primary text-lg leading-normal truncate;
  }

  .product-category,
  .product-subtitle {
    @apply text-gray-600;
  }

  &.is-minimal {
    @apply p-3;

    header {
      width: 73%;
      @screen md {
        @apply w-full;
      }
    }

    .product-title {
      @apply text-base;
      @apply overflow-hidden;
      @apply truncate;
      @apply whitespace-nowrap;
      @apply overflow-ellipsis;
    }

    .product-category,
    .product-subtitle {
      @apply text-xs;
    }

    figure {
      @apply mb-0;
    }

    @apply flex flex-row-reverse justify-end items-center;
    .product-image {
      @apply mt-0;
      @apply w-20;
    }

    .product-title,
    .product-category,
    .product-subtitle {
      @apply text-left;
    }

    @screen sm {
      @apply p-6;
      @apply block;
      .product-image {
        @apply mt-6;
        @apply w-auto;
      }
      .product-title,
      .product-category,
      .product-subtitle {
        @apply text-center;
      }
    }
  }

  &.is-active {
    @apply shadow-big;
  }

  &.is-disabled {
    @apply shadow-none;
    @apply border border-gray-100;
  }
}
</style>
