<template>
  <div
    class="product-price"
    :class="{
      'is-discounted': discount && (price - discount) > 0,
      'is-small': small,
      'is-big': big,
      'is-bold': bold,
      'is-col': isCol,
      'is-row': isRow
    }"
  >
    <div v-if="discount && !price">
      <p class="discount">
        -{{ formattedDiscount }}
      </p>
    </div>
    <div
      v-else-if="discount && (price - discount) > 0"
      class="flex price-discount-wrapper"
      :class="{
        'space-x-2 items-end': isRow,
        'flex-col': isCol
      }"
    >
      <p
        v-if="price"
        class="price"
      >
        {{ formattedPrice }}
      </p>
      <p class="discount">
        {{ formattedDiscount }}
      </p>
    </div>
    <div v-else>
      <p class="price">
        {{ formattedPrice }}
      </p>
    </div>
  </div>
</template>

<script>
  import { computed } from '@nuxtjs/composition-api'
  import { formatPrice } from '~/utils/utils'

  export default {
    name: 'ProductPrice',
    props: {
      price: {
        type: Number,
        default: null
      },
      discount: {
        type: Number,
        default: null
      },
      direction: {
        type: String,
        default: 'row',
        validator: val => ['row', 'col'].includes(val)
      },
      showDecimals: {
        type: Boolean,
        default: false
      },
      small: {
        type: Boolean,
        default: false
      },
      big: {
        type: Boolean,
        default: false
      },
      bold: {
        type: Boolean,
        default: true
      }
    },
    setup (props) {
      const formattedPrice = computed(() => {
        return formatPrice(props.price, props.showDecimals)
      })

      const formattedDiscount = computed(() => {
        return formatPrice(props.discount, props.showDecimals)
      })

      const isCol = computed(() => {
        return props.direction === 'col'
      })

      const isRow = computed(() => {
        return props.direction === 'row'
      })

      return {
        isCol,
        isRow,
        formattedPrice,
        formattedDiscount
      }
    }
  }
</script>

<style scoped lang="scss">
.product-price {
  @apply text-right;

  &.is-bold {
    .price {
      @apply font-bold;
    }
  }

  .price {
    @apply text-base;
    @apply leading-7;
  }

  .discount {
    @apply text-base font-bold;
    @apply text-blue-600;
    @apply leading-7;
  }

  &.is-discounted {
    .price {
      @apply font-normal;
      @apply text-gray-600;
      @apply text-xs;
      @apply line-through;
    }
  }

  &.is-small {
    .price {
      @apply text-lg;
      @apply leading-3;
    }
    &.is-discounted {
      .price {
        @apply text-sm;
      }
    }
    &.is-col {
      //.price-discount-wrapper {
      //  @apply space-y-0;
      //}
    }
    .discount {
      @apply text-lg;
      @apply leading-3;
    }
  }

  &.is-big {
    .price {
      @apply text-xl;
      @apply leading-8;
    }
    &.is-discounted {
      .price {
        @apply text-base;
        @apply leading-7;
      }
    }
    .discount {
      @apply text-xl;
      @apply leading-8;
    }
  }

  @screen sm {
    .price {
      @apply text-lg;
    }

    &.is-discounted {
      .price {
        @apply text-sm;
      }
    }

    .discount {
      @apply text-lg;
    }

    &.is-small {
      .price {
        @apply text-sm;
      }
      &.is-discounted {
        .price {
          @apply text-xs;
        }
      }
      .discount {
        @apply text-sm;
      }
    }

    &.is-big {
      .price {
        @apply text-2xl;
        @apply leading-10;
      }
      &.is-discounted {
        .price {
          @apply text-lg;
        }
      }
      .discount {
        @apply text-2xl;
        @apply leading-10;
      }
    }
  }
}
</style>
