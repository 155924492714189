<template>
  <div
    class="card-base"
    :class="{
      'card-base--interactive': interactive,
    }"
  >
    <slot />
  </div>
</template>

<script>
  export default {
    props: {
      interactive: {
        type: Boolean,
        default: true
      }
    }
  }
</script>

<style lang="scss">

.card-base {
  @apply relative w-full bg-white rounded shadow overflow-hidden z-10;

  &--interactive {
    transition: box-shadow .2s ease(easeOutSine);

    .card-image img {
      transition: .35s ease(easeOutSine);
    }

    &:hover {
      @apply shadow-focus;

      .card-image img {
        transform: scale(1.05);
      }
    }
  }
}

</style>
