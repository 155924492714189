<template>
  <div
    class="badge"
    :class="{
      'primary': primary,
      'small': small
    }"
  >
    <slot />
  </div>
</template>

<script>
  export default {
    props: {
      primary: {
        type: Boolean,
        default: false
      },
      small: {
        type: Boolean,
        default: false
      }
    }
  }
</script>

<style lang="scss" scoped>
  .badge {
    @apply inline-block;
    @apply text-blue-500;
    @apply py-1;
    @apply px-2;
    @apply bg-blue-100;
    @apply font-primary;
    @apply text-sm;
    @apply font-bold;

    &.primary {
      @apply bg-blue-500;
      @apply text-white;
    }

    &.small {
      @apply text-xs;
    }
  }
</style>
