import { productGetters } from '@propeller-commerce/propeller'
import { computed } from '@nuxtjs/composition-api'

export const useAttribute = (product, attribute) => {
  return computed(() => {
    return productGetters.getAttributes(product, [attribute])[0]?.value
  })
}

export const useListAttribute = (product, attribute) => {
  return computed(() => {
    const attr = productGetters.getAttributes(product, [attribute])[0]?.value

    if (!attr) {
      return []
    }

    return attr.split(',')
  })
}
