var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"product-price",class:{
    'is-discounted': _vm.discount && (_vm.price - _vm.discount) > 0,
    'is-small': _vm.small,
    'is-big': _vm.big,
    'is-bold': _vm.bold,
    'is-col': _vm.isCol,
    'is-row': _vm.isRow
  }},[(_vm.discount && !_vm.price)?_c('div',[_c('p',{staticClass:"discount"},[_vm._v("\n      -"+_vm._s(_vm.formattedDiscount)+"\n    ")])]):(_vm.discount && (_vm.price - _vm.discount) > 0)?_c('div',{staticClass:"flex price-discount-wrapper",class:{
      'space-x-2 items-end': _vm.isRow,
      'flex-col': _vm.isCol
    }},[(_vm.price)?_c('p',{staticClass:"price"},[_vm._v("\n      "+_vm._s(_vm.formattedPrice)+"\n    ")]):_vm._e(),_vm._v(" "),_c('p',{staticClass:"discount"},[_vm._v("\n      "+_vm._s(_vm.formattedDiscount)+"\n    ")])]):_c('div',[_c('p',{staticClass:"price"},[_vm._v("\n      "+_vm._s(_vm.formattedPrice)+"\n    ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }