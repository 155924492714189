import { computed } from '@nuxtjs/composition-api'

export const useModal = (root) => {
    const data = computed(() => {
        return root.$store.state.modalComponents.data
    })

    const closeModal = () => {
        root.$store.dispatch('modal/close')
    }

    const openModal = (name, modalData = {}) => {
        root.$store.dispatch('modalComponents/open', {
            name,
            ...modalData
        })
    }

    return {
        data,
        closeModal,
        openModal
    }
}
